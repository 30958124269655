import React from "react"
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
} from 'react-share';
import { FiFacebook, FiLinkedin, FiTwitter } from 'react-icons/fi'

const ShareButton = ({ url, title, description }) => {
    return (
        <>
            <FacebookShareButton url={url} quote={description}>
                <FiFacebook />
            </FacebookShareButton>
            <LinkedinShareButton url={url} title={title} summary={description}>
                <FiLinkedin />
            </LinkedinShareButton>
            <TwitterShareButton url={url} title={description}>
                <FiTwitter />
            </TwitterShareButton>
        </>
    )
};

export default ShareButton
