import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import tw, { styled } from 'twin.macro'
import { SEO } from "../components/seo"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"
import ShareButton from "../components/common/ShareButton"
import Banner from "../components/common/Banner"

const StyledMainContainer = styled.div`
${tw`flex flex-col justify-center max-w-4xl mx-auto md:px-8 px-5 pt-12 bg-primary bg-opacity-5 relative`};
p {
  ${tw`md:text-lg text-base font-medium text-muted pb-6 leading-normal`}
  &:empty {
    ${tw`p-0`}
  }
}
h3 {
${tw`text-xl text-secondary font-semibold pb-2`}
}
h4 {
${tw`text-lg text-orange font-bold pb-2`}
}
h5 {
${tw`text-lg text-primary-dark font-medium pb-2`}
}
figure {
  ${tw`pb-4`}
  figcaption {
    ${tw`hidden`}
  }
}
ol, ul {
  li {
      ${tw`md:text-lg text-base font-medium text-muted leading-normal`}
      &::marker {
          ${tw`text-lg font-bold text-primary-dark`}
      }
  }
}
ol {
${tw`list-decimal pl-6`}
  li {
    ${tw`pb-2`}
    p {
      ${tw`pb-2`}
    }

    ul {
        ${tw`pl-4`}
        li {
            &::marker {
                ${tw`text-lg font-semibold text-primary-dark [content: "> "]`}
            }
        }
    }
  }
}
.share-widget {
  ${tw`flex flex-row gap-2 font-medium items-center relative pb-4 w-full h-auto md:(sticky ml-auto -mr-32 top-32 flex-col h-0 w-20 pb-0)`}

  span {
    ${tw`text-primary-dark font-semibold text-center leading-5`}
  }

  .social {
    ${tw`flex flex-row md:flex-col gap-2`}

    button {
      ${tw`!(p-2 text-lg text-pragraph-color [border: 1px solid #646464] rounded-full)`}

      &:hover {
        ${tw`!text-white`}
      }

      &:first-child {
        &:hover {
          ${tw`!bg-[#1d65e8] !border-[#1d65e8]`}
        }
      }

      &:nth-child(2) {
        &:hover {
          ${tw`!bg-[#18bbea] !border-[#18bbea]`}
        }
      }

      &:nth-child(3) {
        &:hover {
          ${tw`!bg-[#3862ae] !border-[#3862ae]`}
        }
      }
    }
  }
}
`;

const StyledPagination = styled.ul`
  ${tw`w-full flex md:flex-row flex-col gap-6 mx-auto max-w-4xl justify-between bg-white border border-dark/10 p-4 rounded my-5`}

  li {
    ${tw`flex flex-row gap-4`}

    .pagination-image {
      img {
        ${tw`h-20`}
      }
    }

    .pagination-body {
      ${tw`flex flex-col`}

      a {
        ${tw`flex flex-row items-center gap-2 font-medium`}
      }

      h6 {
        ${tw`font-medium`}
      }
    }

    &.next {
      ${tw`flex-row-reverse`}

      .pagination-body {
        ${tw`items-end text-right`}
      }
    }

    &:hover {
      .pagination-body {
        a {
          ${tw`text-secondary`}
        }
      }
    }
  }
`;

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { previous, next } = pageContext
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Blog', link: '/blog' },
    { name: frontmatter.title, link: '' },
  ]
  const url = typeof window !== 'undefined' ? window.location.href : ''
  return (
    <Layout>
      <Banner
        data={frontmatter.title}
        image={frontmatter.featuredImage.publicURL}
        crumbs={crumbs}
      />
      <StyledMainContainer>
        <div className="share-widget">
          <span>Share Now</span>
          <div className="social">
            <ShareButton url={url} title={frontmatter.title} description={frontmatter.description} />
          </div>
        </div>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </StyledMainContainer>
      <StyledPagination
        style={{
          justifyContent: !previous && 'flex-end'
        }}
      >
        {previous && (
          <li>
            <div className="pagination-image">
              <Link to={previous.frontmatter.slug}>
                <img src={previous.frontmatter.featuredImage.publicURL} />
              </Link>
            </div>
            <div className="pagination-body">
              <Link to={previous.frontmatter.slug}>
                <BsArrowLeft />
                <span>Previous</span>
              </Link>
              <h6>
                {previous.frontmatter.title}
              </h6>
            </div>
          </li>
        )}
        {next && (
          <li className="next">
            <div className="pagination-image">
              <Link to={next.frontmatter.slug}>
                <img src={next.frontmatter.featuredImage.publicURL} />
              </Link>
            </div>
            <div className="pagination-body">
              <Link to={next.frontmatter.slug}>
                <span>Next</span>
                <BsArrowRight />
              </Link>
              <h6>
                {next.frontmatter.title}
              </h6>
            </div>
          </li>
        )}
      </StyledPagination>
    </Layout>
  )
};

export default BlogPost

export const Head = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <SEO title={frontmatter.title} description={frontmatter.description} />
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        featuredImage {
            publicURL
        }
      }
    }
  }
`